import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { Container, Row, Col } from "react-bootstrap"
import Modal from "./modal/modal"
import PipefyModal from "./modal/pipefy-modal"
import { Link } from "gatsby"

import { GiGears } from "react-icons/gi"
import { IoMdRocket } from "react-icons/io"
import { FaNetworkWired, FaKey } from "react-icons/fa"
import { RiWifiFill } from "react-icons/ri"
import { BsClipboardData } from "react-icons/bs"

const PipefySections = () => (
  <>
    <section id="status" className="stat-wrapper">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-10 mx-auto mb-4">
            <h2 className="font-weight-medium">
              Repetitive Tasks Cost Your Business in a Big Way
            </h2>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-1 mx-auto">
              <h5 className="font-weight-bold mb-5">
                5 <br />
                Hours
              </h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#f25e30" }}>
              Average Time Employees Spend on Weekly Repetitive Tasks
            </p>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-2 mx-auto">
              <h5 className="font-weight-bold mb-5">
                228 <br />
                Hours
              </h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#791f87" }}>
              Time Lost Per Employee Per Year on Duplicate Tasks
            </p>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-3 mx-auto">
              <h5 className="font-weight-bold mb-5">
                450
                <br /> Hours
              </h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#d69900" }}>
              Average Time Spent Managing Unnecessary Emails Each Year
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      style={{
        background: "#f9f9f9",
        paddingTop: "5rem",
        paddingBottom: "5rem",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="mt-3">
              Manual Tasks Kill Your Productivity and Profitability
            </h2>
            <p className="mt-5">
              Growth means nothing if you can’t scale your systems and
              workflows. Without automated systems, your team will spend
              exponentially more time on repetitive tasks instead of meaningful
              projects.
              <b> The truth:</b> your employees don’t have <i>enough</i> time
              for critical tasks. So, how do you give them more time? You need
              to rethink your processes and workflows, automating away the
              menial tasks. Investing in technology will get you there. But,
              there is high risk and high cost associated with most custom
              solutions. Luckily, you don’t have to go all-in on custom
              applications…
            </p>
            <p>
              With Pipefy, you can give your team the power to automate
              workflows and manage business processes. No coding skills
              required! Need automated employee onboarding? Invoicing?
              Marketing? No matter what department, your team can rapidly build
              the automated solutions they need. Improve efficiency. Reduce data
              errors. Increase visibility. And scale your business leaner and
              faster than ever before.
            </p>
            {/* <div className="mt-4">
              <PipefyModal />
            </div> */}
          </div>
          <div className="col-lg-6 align-self-center pb-5">
            <LazyLoadComponent>
              <iframe
                width="100%"
                height="380px"
                src="https://www.youtube.com/embed/oZYg5XaQXoc"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
                title="Tech Consulting"
                className="rounded"
              ></iframe>
            </LazyLoadComponent>
          </div>
        </div>
      </div>
    </section>
    {/* <section id="case-studies-container">
          <div id="case-studies" className="bg-indigo">
            <div className="container">
              <div className="intro text-center">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                      Case Studies
                    </h5>
                    <h2 className=" my-2 text-white">
                      We Save Clients 1000s of Hours. Every Year. Cash in on
                      the ROI.
                    </h2>
                    <p className="text-white">
                      We helped a national healthcare provider save over 9,000
                      hours in weekly labor costs by developing a single
                      application...
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
          </div>
        </section> */}
    <section className="quick-base-glance" style={{ padding: "5rem 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center mb-5">
              Give Your Employees More Time with Pipefy
            </h2>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <GiGears />
            <h3 className="my-4">Increase Efficiency</h3>
            <p>Automate tasks and connect processes using low code.</p>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <IoMdRocket />
            <h3 className="my-4">Deploy Faster</h3>
            <p>Empower your team to build solutions without bogging down IT.</p>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <RiWifiFill />
            <h3 className="my-4">Connect with Everyone</h3>
            <p>Keep your customers, vendors, and team updated with ease.</p>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <FaNetworkWired />
            <h3 className="my-4">
              Better
              <br />
              Integrations
            </h3>
            <p>
              Connect all your systems and applications with a single platform.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <BsClipboardData />
            <h3 className="my-4">
              Cleaner
              <br />
              Data
            </h3>
            <p>
              Break down silos with integrations and accessible data dashboards.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 mt-5 text-center">
            <FaKey />
            <h3 className="my-4">
              Scalable. Secure.
              <br />
              Flexible.
            </h3>
            <p>
              Pipefy remains security compliant while scaling to meet your
              needs.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section style={{ padding: "5rem 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="mb-1">Transform How Work Gets Done with Pipefy</h2>
          </div>
        </div>
        <div
          className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
            marginTop: "5rem",
          }}
        >
          <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center pl-md-5 text-left-mobile">
            <h3>Finish Tasks Faster and Easier</h3>
            <p className="mt-4">
              Your customers, vendors, and colleagues can make requests for your
              team to complete. You build the workflow, ensuring quick and
              accurate results.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mt-lg-4 mb-lg-4">
            <StaticImage
              placeholder="blurred"
              imgClassName="rounded d-flex w-100 img-fluid"
              src="../images/new-images/pf-1.png"
              alt="Workato Is Simple to Use"
              layout="constrained"
              style={{ height: "20rem" }}
              objectFit="contain"
              loading="lazy"
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 mt-4 mb-4">
            <StaticImage
              placeholder="blurred"
              imgClassName="rounded d-flex w-100 img-fluid"
              src="../images/new-images/pf-2.png"
              alt="Connect Over 300+ Enterprise Apps"
              layout="constrained"
              style={{ height: "20rem" }}
              objectFit="contain"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 text-left-mobile">
            <h3>Automate Away the Busy (Boring) Work</h3>
            <p className="mt-4">
              Free up your team to focus on critical tasks by streamlining the
              repetitive obstacles. Reduce overhead, frustration, burnout, and
              waste.
            </p>
          </div>
        </div>
        <div
          className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
          style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
        >
          <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 px-md-5 text-left-mobile">
            <h3>Collect Data at Every Stage</h3>
            <p className="mt-4">
              No need to pull data. With Pipefy, you can see what’s happening at
              a glance. Real-time reporting for real-time action.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
            <StaticImage
              placeholder="blurred"
              imgClassName="rounded d-flex w-100"
              src="../images/new-images/pf-3.png"
              alt="Complete Control Over Your Processes"
              layout="constrained"
              style={{ height: "20rem" }}
              objectFit="contain"
              loading="lazy"
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 mt-4 mb-4">
            <StaticImage
              placeholder="blurred"
              imgClassName="rounded d-flex w-100 img-fluid"
              src="../images/new-images/pf-4.png"
              alt="Connect Over 300+ Enterprise Apps"
              layout="constrained"
              style={{ height: "20rem" }}
              objectFit="contain"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 text-left-mobile">
            <h3>Use Your Existing Apps Longer</h3>
            <p className="mt-4">
              Operations and customer demands change constantly. Pipefy lets you
              adapt your workflows without abandoning your tool kit.
            </p>
          </div>
        </div>
        <div
          className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
          style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
        >
          <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 px-md-5 text-left-mobile">
            <h3>Connect Everything. Everywhere.</h3>
            <p className="mt-4">
              Connect all your systems and move data between them effortlessly.
              No more wasting time on stitching data together for manual
              reports.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
            <StaticImage
              placeholder="blurred"
              imgClassName="rounded d-flex w-100"
              src="../images/new-images/pf-5.png"
              alt="Complete Control Over Your Processes"
              layout="constrained"
              style={{ height: "20rem" }}
              objectFit="contain"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
    {/* <LazyLoadComponent>
          <div
            className="text-center intro mb-1 bg-gradient"
            style={{ padding: "5rem 0" }}
            id=""
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                    Reviews
                  </h5>
                  <h2 className="my-2 text-white mt-4">What Our Clients Say</h2>
                </div>
              </div>
            </div>
            <Testimonials />
          </div>
        </LazyLoadComponent> */}
    <LazyLoadComponent>
      <div id="quick-base-free-trial">
        <div className="text-center" style={{ backgroundColor: "#2C9382" }}>
          <Container>
            <p className="text-white m-0 py-2 text-18">
              <span className="font-weight-bold">
                HAVE QUESTIONS BEFORE SIGNING UP?{" "}
              </span>
              Give Us A Call At{" "}
              <a
                aria-label="link"
                href="tel:+17207391406"
                onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '720-739-1406', 0);"
                className="text-white"
              >
                (720) 739-1406.
              </a>{" "}
            </p>
          </Container>
        </div>
        <section className="quick-base-trial-banner">
          <Container>
            <Row>
              <Col md={`${9} mx-auto`}>
                <div className="content text-center">
                  <Link aria-label="link" to="/">
                    <StaticImage
                      placeholder="blurred"
                      imgClassName="mb-3"
                      src="../../static/assets/footer-logo.png"
                      alt="Quandary Logo White"
                      layout="constrained"
                      loading="lazy"
                    />
                  </Link>
                  <h4 className="text-white">
                    Custom Applications Built Faster
                  </h4>
                  <hr className="bg-light" />
                  <h2 className="text-light-blue" style={{ color: "#2C9382" }}>
                    Discover How Pipefy Streamlines Workflows
                  </h2>
                  <hr className="bg-light" />
                  <Modal
                    modalSize="lg"
                    modalClass="modal-video"
                    btnClass="btn-play"
                    html={
                      <StaticImage
                        placeholder="blurred"
                        imgClassName="mb-3"
                        src="../images/playbuttonslimwhite.png"
                        alt="play button"
                        layout="constrained"
                        style={{ width: 110, height: 110 }}
                        loading="lazy"
                      />
                    }
                  >
                    <iframe
                      title="video"
                      width="100%"
                      height={506}
                      src="https://www.youtube.com/embed/G2ZP5YKGFXM"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Modal>
                  <p className="my-3 text-18 text-white">
                    Break free from spreadsheets with custom applications
                    designed for your unique business needs.
                  </p>
                  {/* <p className="my-3 text-18 text-white">
                      Need more details? Check out the video below for more
                      information on Quickbase
                    </p> */}
                  <div className="d-flex justify-content-center mt-4">
                    {/* <Modal
                          modalSize="md"
                          modalClass="modal-signup"
                          btnClass="btn btn-quick-signup"
                          html={
                            <div>
                              <i
                                className="fa fa-check-square-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;&nbsp;TRY PIPEFY FREE TODAY!
                            </div>
                          }
                        >
                          <div id="signupForm" className="px-4 pb-4">
                            <div className="content text-center">
                              <StaticImage
                                placeholder="blurred"
                                src="../images/QCG-Primary-logo-_1_.png"
                                alt="Quandary logo"
                                layout="constrained"
                                style={{ width: 250 }}
                              />
                              <div
                                className="p-2 my-4"
                                style={{ backgroundColor: "#2C9382" }}
                              >
                                <h4 className="text-white m-0 font-weight-normal">
                                  CLAIM YOUR FREE TRIAL!
                                </h4>
                              </div>
                              <p className="text-dark">
                                Enter your name and email address below to claim
                                your
                                <br />
                                <span className="font-weight-bold">
                                  30-Day Free Trial of Quickbase TODAY!
                                </span>
                              </p>
                              <WebriQForm
                                id="freeTrialform"
                                name="30-Day Free Trial of Quickbase Form"
                                className="signup-form"
                                data-form-id="5e324dbcaf30e913f85ad570"
                                data-thankyou-url="/thank-you"
                              >
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="First Name"
                                    className="form-control"
                                    placeholder="First Name"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="Last Name"
                                    className="form-control"
                                    placeholder="Last Name"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="email"
                                    name="Email"
                                    className="form-control"
                                    placeholder="Your Best Email Address"
                                    required
                                  />
                                </div>
                                <div className="form-group mt-4">
                                  <div className="webriq-recaptcha" />
                                </div>
                                <div className="form-group mt-4">
                                  <button
                                    aria-label="btn"
                                    type="submit"
                                    className="btn btn-primary free-animate btn-quick animated infinite swing slow text-18 w-100"
                                  >
                                    <i
                                      className="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    YES, SIGN ME UP!
                                  </button>
                                </div>
                              </WebriQForm>
                            </div>
                          </div>
                        </Modal> */}

                    <div>
                      {/* <a
                            className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
                            href="https://www.pipefy.com/signup/"
                            alt="Reviews"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;TRY PIPEFY FREE TODAY!
                          </a> */}
                      <PipefyModal />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </LazyLoadComponent>
  </>
)

export default PipefySections
